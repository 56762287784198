@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Roboto Slab', serif !important;
  height: 100%;
  background-color: black;
}
.MuiOutlinedInput-root{
  /* background-color: white !important; */
  border: '1px solid #fee900 !important';
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media screen and (min-width: 901px) {
  .btn-connect{
    
    font-size: 20px !important ;
  }
}
@media screen and (max-width: 900px) {
  .btn-connect{
    margin-top: 30px !important;
    font-size: 16px ;
  }
}

@media screen and (max-width: 700px) {
  .btn-connect{
    margin-top: 30px !important;
    font-size: 12px;
    width: 150px !important;
  }
}
@media screen and (max-width: 400px) {
  .btn-connect{
    margin-top: 30px !important;
    font-size: 12px ;
    width: 150px !important;
  }
}